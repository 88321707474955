/* React Imports */
import { useEffect, useMemo, useState } from 'react'

/* Third party Imports */
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'


/* Local Imports */
import verifyEmailImage from '../../../../assets/verify-email.svg'
import verifyEmailImageSuccess from '../../../../assets/verify-email-success.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../../../services/keycloak'

export default function VerifyEmail() {
    
    const { t } = useTranslation('verifyEmail')
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const navigate = useNavigate()

    const location = useLocation();

    const { token } = queryString.parse(window.location.search);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (token) {
            verifyEmail(token)
            .then(()=> {
                setLoading(false)
                    setIsEmailVerified(true)
                    setTimeout(() => {
                        navigate('/')
                    }, 3000);
            })
            .catch((e) => console.log("Something went wrong!!!!"))
        } 
    }, [token])

    return (

        <div className="flex justify-center	items-center align-middle flex-col">
            {isEmailVerified
                ?
                <>
                    <img src={verifyEmailImageSuccess} alt="verify-email" className="mb-10" />
                    <div className="mb-6">
                        <h1 className='text-3xl'>{t('success.title')}</h1>
                    </div>

                    <div className="mb-10 text-center	">
                        <p className='text-base text-Grey-shade'>{t('success.description')}</p>
                    </div>
                </>
                :
                <>
                    <img src={verifyEmailImage} alt="verify-email" className="mb-10" />

                    <div className="mb-6">
                        <h1 className='text-3xl'>{t('title')}</h1>
                    </div>

                    <div className="mb-10 text-center	">
                        <p className='text-base text-Grey-shade'>{t('description1')} {location.state.email}</p>
                        <p className="mb-6 text-base text-Grey-shade">{t('description2')}</p>
                    </div>
                </>
            }
        </div>

    )
}
