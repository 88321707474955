import { SyntheticEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import eyeIcon from '../../../../assets/eye-icon.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/misc/Buttonv2';
import {
	ButtonSize,
	ButtonVariant,
} from '../../../components/misc/Buttonv2/types';
import useAuthenticate from '../../../../hooks/effects/useAuthenticate';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useWebsiteRedirectParams } from './useWebsiteRedirectParams';


export default function Login() {
	const { t } = useTranslation('login');

	const { setUser } = useContext(AuthContext);

	const { authenticateWithCredentials } = useAuthenticate({ setUser });

	useWebsiteRedirectParams();

	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const navigate = useNavigate();

	const login = async (e: SyntheticEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			const success = await authenticateWithCredentials(
				username,
				password,
			);
			if (!success) {
				setError(t('something-went-wrong'));
			} 
		} catch (error: any) {
			const errorMsg = error?.response?.data?.error_description;
			if (errorMsg?.includes('Account is not fully set up')) {
				navigate(`/sign-up/verify-email`, {
					state: {
						email: username,
					},
				});
				return;
			} else if (errorMsg?.includes('Invalid user credentials')) {
				setError(t('invalid-credentials'));
			} else {
				setError(t('something-went-wrong'));
			}
		}
		setLoading(false);
	};

	return (
		<form className="h-full relative" onSubmit={login}>
			<div className="w-full flex justify-start mb-10">
				<h1 className="text-3xl text-Black-deafult">{t('title')}</h1>
			</div>
			<div className="w-full flex flex-col space-y-7">
				<div>
					<label className="mb-2 text-base text-grey-shade">
						{t('email')}
					</label>
					<input
						required
						value={username}
						type="text"
						placeholder={t('email-placeholder')}
						onChange={(value: any) => {
							setError(null);
							setUsername(value.currentTarget.value);
						}}
						className={`border-solid block w-full border-grey-tint bg-white text-lg text-black rounded py-6 ${
							error ? 'border-2 border-red-500 text-red-500' : ''
						}`}
					/>
				</div>
				<div>
					<label className="mb-2 text-base text-grey-shade">
						{t('password')}
					</label>
					<div className="flex flex-row">
						<input
							required
							type={passwordVisible ? 'text' : 'password'}
							value={password}
							placeholder={t('password-placeholder')}
							onChange={(value: any) => {
								setError(null);
								setPassword(value.currentTarget.value);
							}}
							className={`border-solid block w-full border-grey-tint bg-white text-lg text-black rounded py-6  ${
								error
									? 'border-2 border-red-500 text-red-500'
									: ''
							}`}
						/>
						<img
							src={eyeIcon}
							alt="password-revealer"
							className="-ml-10"
							onClick={() => setPasswordVisible(!passwordVisible)}
						/>
					</div>
				</div>

				{error && (
					<div className="bg-Grey-background px-6 py-4 text-red-500">
						{error}
					</div>
				)}

				<Button
					type="submit"
					onClick={login}
					variant={ButtonVariant.PRIMARY}
					size={ButtonSize.LARGE}
					className="w-full py-6 bg-Blueberry-dark-default rounded text-lg text-white"
					disabled={!!error}
				>
					{t('login-button')}
				</Button>

				<div className="w-full flex flex-col space-y-6">
					<p className="text-base text-grey-shade text-center">
						{t('forgot')}
						<span
							onClick={() => navigate('/forgot-password')}
							className="text-Blueberry-dark-default cursor-pointer"
						>
							{t('reset')}
						</span>
					</p>
					<p className="w-full absolute bottom-0 text-base text-grey-shade text-center">
						{t('no-account')}
						<span
							onClick={() => navigate('/sign-up')}
							className="text-Blueberry-dark-default cursor-pointer"
						>
							{t('register')}
						</span>
					</p>
				</div>
			</div>
		</form>
	);
}
